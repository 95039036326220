import React, { useState, useEffect } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FilledTextFieldProps } from '@mui/material/TextField';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrochipAi } from '@fortawesome/pro-light-svg-icons';
import { ITextFieldProps } from './text-field-props';
import { TextField } from './text-field';
import { ColorPalette } from '../config';

export interface IMaxLengthTextFieldProps extends ITextFieldProps {
  maxLength: number;
  maxLengthError?: string;
  lazy?: boolean;
  showAiIcon?: boolean;
  forceMaxLength?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      '& span:last-child': {
        marginLeft: 'auto',
      },
    },
  })
);

/*
 * A specialized textbox that displays current length and maximum number of characters as helperText.
 * Note! Textbox is *not* limited to maxLength (allowing paste of long text), but the textbox enters error state.
 */
export function MaxLengthTextField(props: React.PropsWithChildren<IMaxLengthTextFieldProps>) {
  const { maxLength, maxLengthError, helperText, label, showAiIcon, onChange, onBlur, lazy, value, error, errorText, forceMaxLength, children, ...rest } = props;
  const classes = useStyles();
  const [stateValue, setStateValue] = useState(value);

  useEffect(() => {
    if (lazy) {
      setStateValue(value);
    }
  }, [value]);

  function getLength() {
    if (lazy) {
      return stateValue ? (stateValue as string).length : 0;
    }
    return value ? (value as string).length : 0;
  }

  const length = getLength();
  const changedProps = {} as Partial<ITextFieldProps>;

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (lazy) {
      setStateValue(event.target.value);
    } else {
      onChange && onChange(event);
    }
  }

  function handleBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) {
    lazy && onChange && onChange(event);
    onBlur && onBlur(event);
  }

  if (length > maxLength && !error) {
    changedProps.error = true;
    changedProps.errorText = `${maxLengthError || 'Teksten indeholder for mange tegn'} (${length}/${maxLength})`;
  }

  const helperTextAlignment = changedProps.error || error || helperText ? 'left' : 'right';

  const FormHelperTextProps: FilledTextFieldProps['FormHelperTextProps'] = {
    style: { textAlign: helperTextAlignment },
  };

  return (
    <TextField
      {...rest}
      {...changedProps}
      helperText={
        <span className={classes.wrapper}>
          <span>{helperText}</span>
          <span>
            {length}/{maxLength}
          </span>
        </span>
      }
      FormHelperTextProps={FormHelperTextProps}
      onChange={handleChange}
      onBlur={handleBlur}
      lazy={false}
      value={lazy ? stateValue : value}
      maxLength={forceMaxLength ? maxLength : undefined}
      label={
        showAiIcon ? (
          <Box display="flex" gap={0.5}>
            <Box>{label}</Box>
            <FontAwesomeIcon size="lg" icon={faMicrochipAi} color={ColorPalette.HotAurora} title="Dette felt kan indeholde tekst, der er baseret på AI." />
          </Box>
        ) : (
          label
        )
      }
      error={error || changedProps.error}
      errorText={errorText || changedProps.errorText}
    >
      {children}
    </TextField>
  );
}
