/**
 * REMINDER:
 * When making changes to these ResourceTypes, also remember to make the changes to the backend list of ResourceTypes in "src\infrastructure-modules\fasit-security\Fasit.Security.Resources\ResourceTypes.cs".
 */
export class ResourceTypes {
  static readonly Activity = 'Activity';
  static readonly ActivitySupplement = 'ActivitySupplement';
  static readonly AuditLog = 'AuditLog';
  static readonly Booking = 'Booking';
  static readonly BusinessConfiguration = 'BusinessConfiguration';
  static readonly ChangeLog = 'ChangeLog';
  static readonly Chart = 'Chart';
  static readonly Citizen = 'Citizen';
  static readonly CitizenLetter = 'CitizenLetter';
  static readonly Company = 'Company';
  static readonly CompanyLetter = 'CompanyLetter';
  static readonly CompanyRecruitment = 'CompanyRecruitment';
  static readonly CompanyVisitPreparation = 'CompanyVisitPreparation';
  static readonly Cv = 'CV';
  static readonly Dashboard = 'Dashboard';
  static readonly Document = 'Document';
  static readonly SocialCases = 'SocialCases';
  static readonly Interview = 'Interview';
  static readonly InterviewDeadline = 'InterviewDeadline';
  static readonly PersonCategory = 'PersonCategory';
  static readonly InterviewPreparation = 'InterviewPreparation';
  static readonly InterviewPreparationSickLeave = 'InterviewPreparationSickLeave';
  static readonly JobAd = 'JobAd';
  static readonly JobLog = 'JobLog';
  static readonly JobReferral = 'JobReferral';
  static readonly JournalNote = 'JournalNote';
  static readonly JournalReport = 'JournalReport';
  static readonly Offer = 'Offer';
  static readonly Outbox = 'Outbox';
  static readonly UnemploymentFund = 'UnemploymentFund';
  static readonly UnemploymentFundContactDetails = 'UnemploymentFundContactDetails';
  static readonly Worklist = 'Worklist';
  static readonly WorklistAccessControl = 'WorklistAccessControl';
  static readonly FasitSearch = 'FasitSearch';
  static readonly CompulsoryNotificationEvent = 'CompulsoryNotificationEvent';
  static readonly User = 'User';
  static readonly Team = 'Team';
  static readonly SubJobcenter = 'SubJobcenter';
  static readonly IntelligentMatch = 'IntelligentMatch';
  static readonly Case = 'Case';
  static readonly ContactGroup = 'ContactGroup';
  static readonly TransactionLog = 'TransactionLog';
  static readonly WorklistSharing = 'WorklistSharing';
  static readonly CandidateReferral = 'CandidateReferral';
  static readonly CrmDepartment = 'CrmDepartment';
  static readonly CrmRoles = 'CrmRoles';
  static readonly UserProfile = 'UserProfile';
  static readonly ExternalOperatorAgreement = 'ExternalOperatorAgreement';
  static readonly DocumentTemplate = 'DocumentTemplate';
  static readonly FasitAdministration = 'FasitAdministration';
  static readonly CitizenCrowdLetter = 'CitizenCrowdLetter';
  static readonly Crm = 'Crm';
  static readonly Plan = 'Plan';
  static readonly CompanyTask = 'CompanyTask';
  static readonly CitizenTask = 'CitizenTask';
  static readonly GeneralTask = 'GeneralTask';
  static readonly IncomingMailTask = 'IncomingMailTask';
  static readonly ContactPerson = 'ContactPerson';
  static readonly Availability = 'Availability';
  static readonly Enrollment = 'Enrollment';
  static readonly PersonGroupMarking = 'PersonGroupMarking';
  static readonly CompanyNote = 'CompanyNote';
  static readonly Report = 'Report';
  static readonly StandardAppendix = 'StandardAppendix';
  static readonly GuideLinks = 'GuideLinks';
  static readonly ExpectedJobResumption = 'ExpectedJobResumption';
  static readonly ActivationLevel = 'ActivationLevel';
  static readonly Grant = 'Grant';
  static readonly SupportAssessment = 'SupportAssessment';
  static readonly QuestionnaireFeedback = 'QuestionnaireFeedback';
}

/**
 * REMINDER:
 * When making changes to these ResourceOperations, also remember to make the changes to the backend list of ResourceOperations in "src\infrastructure-modules\fasit-security\Fasit.Security.Resources\ResourceOperations.cs".
 */
export const ResourceOperations = {
  Read: 'Read',
  Write: 'Write',
  Access: 'Access',

  Offer: {
    Update: 'Update',
    Create: 'Create',
  },

  Activity: {
    Approve: 'Approve',
  },

  Citizen: {
    Create: 'Create',
  },

  JournalNote: {
    Create: 'Create',
    UpdateMetadata: 'UpdateMetadata',
    Correct: 'Correct',
    ReviseStatus: 'ReviseStatus',
  },

  Case: {
    ReOpenOverdueCase: 'ReOpenOverdueCase',
    Create: 'Create',
  },

  Chart: {
    UpdateAll: 'UpdateAll',
  },

  Worklist: {
    UpdateAll: 'UpdateAll',
  },

  Dashboard: {
    UpdateAll: 'UpdateAll',
  },

  Booking: {
    SynchronizeAllFromDfdg: 'SynchronizeAllFromDfdg',
  },

  CompanyInteraction: {
    Delete: 'Delete',
  },

  CompanyDetails: {
    MarkAsIrrelevant: 'MarkAsIrrelevant',
  },

  User: {
    OverwriteIdentities: 'OverwriteIdentities',
  },
};
